<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 128 128"
    :aria-labelledby="iconName"
    role="presentation"
    fill="none"
  >
    <title :id="iconName" :lang="$i18n.locale" v-if="title != null">
      {{ $t(title) }}
    </title>
    <g :style="`--color: ${iconColor}`">
      <slot />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "box"
    },
    width: {
      type: [Number, String],
      default: 128
    },
    height: {
      type: [Number, String],
      default: 128
    },
    iconColor: {
      type: String,
      default: "currentColor"
    },
    title: {
      type: String,
      required: false,
      default: null
    }
  }
};
</script>
